import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Select, Result, Radio } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  legalType: 'PRIVATE',
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  avatar: undefined,
  role: [],
};

const UserForm = ({ data, updateHandler, createHandler, result, setResult, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [legalType, setLegalType] = useState(data ? data.legalType : 'PRIVATE');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;

  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleLegalType = (e) => {
    setLegalType(e.target.value);
    form.setFieldsValue({ legalType: e.target.value });
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`${isNew ? 'NEW' : 'EDIT'} USER`} bordered={false}>
            {!result && (
              <Form
                {...layout}
                name='basic'
                initialValues={user}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
              >
                <Form.Item name='legalType' label='Legal Type'>
                  <Radio.Group onChange={handleLegalType}>
                    <Radio value='PRIVATE'>Private</Radio>
                    <Radio value='COMPANY'>Company</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label='Email'
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter email!',
                    },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                  label='First name'
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter first name!',
                    },
                  ]}
                >
                  <Input placeholder='First name' />
                </Form.Item>

                <Form.Item
                  label='Last name'
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter last name!',
                    },
                  ]}
                >
                  <Input placeholder='Last name' />
                </Form.Item>

                <Form.Item label='Phone' name='phone'>
                  <Input placeholder='Phone' />
                </Form.Item>

                <Form.Item label='Address' name='address'>
                  <Input placeholder='Address' />
                </Form.Item>

                <Form.Item
                  label='City'
                  name='city'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter city!',
                    },
                  ]}
                >
                  <Input placeholder='City' />
                </Form.Item>

                <Form.Item label='Zip' name='zip'>
                  <Input placeholder='Zip' />
                </Form.Item>

                <Form.Item
                  label='Country'
                  name='country'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter country!',
                    },
                  ]}
                >
                  <Input placeholder='Country' />
                </Form.Item>

                {legalType === 'COMPANY' && (
                  <>
                    <Form.Item
                      label='Company Name'
                      name='companyName'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter company name!',
                        },
                      ]}
                    >
                      <Input placeholder='Company Name' />
                    </Form.Item>

                    <Form.Item
                      label='Tax ID'
                      name='taxId'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter tax ID!',
                        },
                      ]}
                    >
                      <Input placeholder='Tax ID' />
                    </Form.Item>
                  </>
                )}

                {isNew && (
                  <Form.Item
                    label='Password'
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your password!',
                      },
                    ]}
                  >
                    <Input.Password placeholder='Password' />
                  </Form.Item>
                )}

                <Form.Item
                  label='Role'
                  name='role'
                  rules={[
                    {
                      required: true,
                      message: 'Please select role!',
                    },
                  ]}
                >
                  <Select mode='multiple' placeholder='Role'>
                    <Select.Option value='admin'>Admin</Select.Option>
                    <Select.Option value='user'>User</Select.Option>
                  </Select>
                </Form.Item>

                {!isNew && (
                  <Form.Item
                    label='Status'
                    name='status'
                    rules={[
                      {
                        required: true,
                        message: 'Please select status!',
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option value='ACTIVE'>ACTIVE</Select.Option>
                      <Select.Option value='SUSPENDED'>SUSPENDED</Select.Option>
                      <Select.Option value='DELETED'>DELETED</Select.Option>
                      <Select.Option value='WAITING_FOR_ACTIVATION'>WAITING_FOR_ACTIVATION</Select.Option>
                    </Select>
                  </Form.Item>
                )}

                <Form.Item label='Avatar' className='upload-wrapper right' name='avatar'>
                  <UploadBox editHandler={avatarHandler} deleteHandler={deleteAvatarHandler} image={image} index={0} name='avatar' />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            )}
            {result && (
              <Result
                title='User has been created and verification mail has been sent'
                extra={
                  <Button type='primary' key='console' onClick={() => setResult(false)}>
                    Ok
                  </Button>
                }
              />
            )}
          </Card>

          {modal.visible && (
            <GalleryModal
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserForm;

import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import RefundForm from '../../components/forms/RefundForm';

const EditRefund = (props) => {
	const history = useHistory();
	const currentuser = useContext(UserContext);
	const [refund, fetchRefund] = useAxios('', {}, currentuser.data.token, 'get');
	const { refundId } = props.match.params;

	useEffect(() => {
		if (refundId) fetchRefund(`${SERVER_URL}/refunds/${refundId}`, {});
	}, [fetchRefund, refundId]);

	const onSubmit = async (formData) => {
		const method = 'put';
		const route = `${SERVER_URL}/refunds/${refundId}`;

		try {
			await Axios[method](route, { status: formData.status }, {
				withCredentials: false,
				headers: { Authorization: `Bearer ${currentuser.data.token}` },
			});

			notification.success({
				message: `Refund updated.`,
				placement: 'bottomRight',
			});
			history.push('/admin/refunds');
		} catch (error) {
			const msg = error.response ? error.response.data.message : error.message ? error.message : error;
			console.log(msg);
			notification.error({
				message: msg,
				placement: 'bottomRight',
			});
		}
	};


	const isDataFetched = !refund.isLoading && Object.keys(refund.data).length > 0 && currentuser.language;

	return (
		<div style={{ padding: '8px' }}>
			<div className='actions-block'>
				<Link to='/admin/refunds'>
					<Button type='primary'>All refunds</Button>
				</Link>
			</div>

			<div>
				{!isDataFetched && (
					<div style={{ textAlign: 'center' }}>
						<LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
					</div>
				)}
				{refundId && !refund.isError && refund.data && isDataFetched && (
					<RefundForm
						data={refund.data}
						language={currentuser.language}
						onSubmit={onSubmit}
						token={currentuser.data.token}
					/>
				)}
			</div>
		</div>
	);
};

export default EditRefund;

import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { Table } from '../../components/tables';

import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'avatar',
  'address',
  'zip',
  'taxId',
  'phone',
  'legalType',
  'wishList',
];

const Users = () => {
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchUsers(`${SERVER_URL}/users`, []);
  }, [fetchUsers]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (users.data.items && users.data.items.length > 0) {
    const keys = Object.keys(users.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (users.data.items && users.data.items.length > 0) {
    tableData = users.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/new-user'>
          <Button type='primary'>New user</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center', overflowX:'auto' }}>
        {users.isLoading && (
          <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
        )}
        {!users.isLoading && users.data.items && users.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteUserHandler}
            columnKeys={columnKeys}
            title='User'
            editPath='/admin/edit-user/'
            viewPath='/admin/view-user/'
          />
        )}
        {!users.isLoading && users.data.items && users.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Users;

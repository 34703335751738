import React, { useEffect, useState } from 'react';
import useAxios from '../../hooks/useAxios';
import { Modal, Row, Col, Tabs, Checkbox, Spin, Upload, Tooltip, Input, message, notification } from 'antd';
import { UploadOutlined, SearchOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const GalleryModal = ({
  visible,
  formKey,
  limit,
  SERVER_URL,
  token,
  onInsert,
  onCancel,
  form,
  imageType,
  imageHeight,
  imageSavePath,
  imageId,
  imageIndex,
}) => {
  const [images, fetchImages] = useAxios('', null, token, 'get');
  const [gallery, setGallery] = useState([]);
  const [selected, setSelected] = useState([]);
  const [currentSelected, setCurrentSelected] = useState([]);
  const [uploaded, setUploaded] = useState([]);
  const [activeTab, setActiveTab] = useState('GALLERY');
  const [search, setSetSearch] = useState('');

  /**
   * fetch all images from db
   */
  useEffect(() => {
    fetchImages(`${SERVER_URL}/images`, []);
  }, [fetchImages, SERVER_URL]);

  /**
   * If there is previous images in form -> set them to selected state
   */
  useEffect(() => {
    const prevSelected = form.getFieldValue(formKey);
    if (prevSelected?._id) {
      setSelected([prevSelected._id]);
    }
    if (Array.isArray(prevSelected) && prevSelected?.length > 0) {
      setSelected(prevSelected.map((item) => item._id));
    }
  }, [setSelected, form, formKey, limit]);

  /**
   * Sort images so that selected images are first in gallery
   */
  useEffect(() => {
    if (images.data?.length > 0) {
      if (selected.length > 0) {
        const notSelectedImages = images.data.filter((image) => !selected.includes(image._id));
        const selectedImages = selected.map((id) => {
          const img = images.data.find((image) => image._id === id);
          return img;
        });
        setGallery([...selectedImages, ...notSelectedImages]);
      } else {
        setGallery(images.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const selectHandler = (id) => {
    if (limit > 1) {
      if (selected.includes(id)) {
        const removed = selected.filter((item) => item !== id);
        setSelected(removed);
      }
      if (!selected.includes(id) && selected.length < +limit) {
        setSelected([...selected, id]);
      }
      if (selected.length >= +limit) {
        return notification.error({
          message: `You can select max ${limit} images`,
        });
      }
    }
    if (limit === 1) {
      setCurrentSelected(id);
      if (imageIndex >= 0) {
        const newSelected = [...selected];
        if (selected.includes(id)) {
          newSelected.splice(imageIndex, 1);
        } else {
          newSelected.splice(imageIndex, 1, id);
        }
        setSelected(newSelected);
      } else {
        setSelected(!selected.includes(id) ? [id] : []);
      }
    }
  };

  const beforeUpload = (file) => {
    const isAllowedFormat = ['image/jpeg', 'image/png'].includes(file.type);
    if (!isAllowedFormat) {
      notification.error({
        message: 'You can only upload JPG or PNG file!',
        placement: 'bottomRight',
      });
      return Upload.LIST_IGNORE;
    }
    const isAllowedSize = file.size / 1024 / 1024 < 50;
    if (!isAllowedSize) {
      notification.error({
        message: 'Image must smaller than 8MB!',
        placement: 'bottomRight',
      });
      return false;
    }
    return isAllowedFormat && isAllowedSize;
  };

  const onUpload = async ({ file, fileList }) => {
    if (file.status === 'done') {
      if (file.response && file.response.file) {
        message.success(`${file.response.file.name} file uploaded successfully.`);
        setUploaded([...uploaded, file.response.image]);
        selectHandler(file.response.image._id);
      }
    } else if (file.status === 'error') {
      message.error(`${file.response.file.name} file upload failed.`);
    }
    if (fileList.length > 1 && fileList.every((f) => f.status === 'done')) {
      setUploaded(fileList.map((f) => f.response.image));
    }
  };

  const onOk = () => {
    const selectedImages = selected.map((selectedImg) => {
      const image = [...gallery, ...uploaded].find((image) => image._id === selectedImg);
      return image;
    });
    onInsert(limit === 1 && !(imageIndex >= 0) ? selectedImages[0] : selectedImages);
    onCancel();
  };

  return (
    <Modal
      centered
      width='95%'
      visible={visible}
      title={<strong>IMAGE GALLERY</strong>}
      okText='INSERT'
      cancelText='CANCEL'
      maskClosable={false}
      onOk={onOk}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
    >
      <Tabs
        tabPosition='top'
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        style={{ height: '650px', overflowY: 'auto' }}
      >
        <Tabs.TabPane tab={`GALLERY (${images?.data?.length || 0})`} key='GALLERY'>
          <Spin spinning={images.isLoading} tip='Loading images...'>
            <Input
              addonAfter={<SearchOutlined style={{ marginLeft: '5px' }} />}
              value={search}
              onChange={(e) => setSetSearch(e.target.value)}
              style={{ width: '250px', marginBottom: '12px' }}
              placeholder='Search by filename'
            />
            <Row gutter={[8, 8]}>
              {gallery?.length > 0 &&
                gallery
                  .filter((image) => image.originalname.toLowerCase().includes(search.toLowerCase()))
                  .map((image, i) => {
                    return (
                      <Col xs={12} sm={8} md={6} lg={4} xl={3} xxl={2} key={image._id}>
                        <div
                          style={{
                            height: '150px',
                            position: 'relative',
                            background: '#ccc',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            title={image.originalname}
                            src={`${SERVER_URL}${image.url}`}
                            alt={image.originalName}
                            style={{ position: 'absolute', maxHeight: '148px', width: 'auto' }}
                          />
                          <Tooltip title={selected.includes(image._id) ? 'Deselect' : 'Select'}>
                            <Checkbox
                              style={{ position: 'absolute', top: '10px', right: '10px' }}
                              checked={selected.includes(image._id)}
                              onChange={() => selectHandler(image._id)}
                              disabled={
                                imageId
                                  ? image._id !== imageId &&
                                    selected.includes(image._id) &&
                                    currentSelected !== image._id
                                  : false
                              }
                              // disabled={imageIndex ? imageIndex !== i && selected.includes(image._id) : false}
                            />
                          </Tooltip>
                        </div>
                        <p
                          style={{
                            fontSize: '0.725rem',
                            textAlign: 'center',
                          }}
                        >
                          {image.originalname}
                        </p>
                      </Col>
                    );
                  })}
            </Row>
          </Spin>
        </Tabs.TabPane>

        <Tabs.TabPane tab='UPLOAD' key='UPLOAD' forceRender>
          <Dragger
            multiple={limit > 1}
            maxCount={limit}
            action={`${SERVER_URL}/upload-local-image`}
            accept='.jpg,.jpeg,.png,.bmp,.svg'
            name='image'
            getValueFromEvent={normFile}
            headers={{
              type: imageType,
              resize: true,
              // resizemob: false,
              height: imageHeight,
              Authorization: `Bearer ${token}`,
              uri: imageSavePath,
              // urimob: imageSavePath + 'mob/',
            }}
            beforeUpload={beforeUpload}
            onChange={onUpload}
          >
            <p className='ant-upload-drag-icon'>
              <UploadOutlined />
            </p>
            <p className='ant-upload-text'>Click or drag file to this area to upload</p>
            <p className='ant-upload-hint'>Upload MAX {limit} images</p>
          </Dragger>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default GalleryModal;

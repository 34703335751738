import React, { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../config';

const UploadBox = ({ editHandler, deleteHandler, image, index, name }) => {
  const [visible, setVisible] = useState(false);
  const imageUrl = image && image.url ? image.url : image;
  const getUrl = (itemUrl) => {
    let url = '';
    if (itemUrl) url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  useEffect(() => {
    const overlay = document.getElementById(`upload-box-${name}-${index}`);
    if (overlay) {
      overlay.addEventListener('mouseenter', () => setVisible(true));
      overlay.addEventListener('mouseleave', () => setVisible(false));
      return () => {
        overlay.removeEventListener('mouseenter', () => setVisible(true));
        overlay.removeEventListener('mouseleave', () => setVisible(false));
      };
    }
  });

  return (
    <>
      {image ? (
        <div
          id={`upload-box-${name}-${index}`}
          style={{
            position: 'relative',
            width: '100px',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '0.5px solid #bbb',
            background: '#eee',
            borderRadius: '5px',
            cursor: 'pointer',
            margin: '0 5px 5px 0',
          }}
        >
          <img
            src={getUrl(imageUrl)}
            alt='upload'
            title='Select from gallery or upload'
            style={{ maxHeight: '96px', maxWidth: '96px', borderRadius: '5px' }}
          />
          <div
            style={{
              position: 'absolute',
              display: visible ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              width: '96px',
              height: '96px',
              background: 'rgba(0, 0, 0, 0.6)',
              borderRadius: '5px',
              color: 'white',
              fontSize: '20px',
            }}
          >
            <EditOutlined style={{ padding: '5px' }} title='Change image' onClick={editHandler} />
            <DeleteOutlined style={{ padding: '5px' }} title='Remove image' onClick={deleteHandler} />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: '100px',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            border: '0.5px solid #bbb',
            background: '#eee',
            borderRadius: '5px',
            cursor: 'pointer',
            margin: '0 5px 5px 0',
          }}
          title='Add image'
          onClick={() => editHandler()}
        >
          <PlusOutlined />
          <div>Add image</div>
        </div>
      )}
    </>
  );
};

export default UploadBox;

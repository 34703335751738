import React, { useState, useEffect } from 'react';
import { Tabs, Divider, Input, Button, Form } from 'antd';

const formInit = {
  value: {},
};

const ValueForm = ({ setEdit, attrId, language, onSubmit, value, attribute }) => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState(language.selected.code);
  ['createdAt', 'updatedAt', '__v'].forEach((key) => delete value[key]);
  let initialValues = { ...formInit, ...value };

  useEffect(() => {
    form.resetFields();
  }, [value, form]);

  // useEffect(()=>{alert(JSON.stringify(attribute))},[])

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <button onClick={() => { alert(JSON.stringify(initialValues?.value['sr'])); }}>CLICK MEEEEEE</button>
          <span>ADD / EDIT VALUE</span>
          <span
            style={{ cursor: 'pointer', padding: '6px', background: 'red' }}
            onClick={() => {
              setEdit({ visible: false, attrId: null, value: {} });
            }}
          >
            X
          </span>
        </h4>
      </div>
      <div className='panel-body'>
        <Form
          layout='vertical'
          initialValues={initialValues}
          onFinish={(values) => onSubmit(values, attrId, !value.value)}
          form={form}
        >
          <button onClick={() => {
            alert(form.getFieldValue('value')['sr']);
          }}>LOGS</button>
          <h3 style={{ marginBottom: '8px' }}>{tab} language</h3>
          <Tabs onChange={(key) => setTab(key)} tabPosition='left' defaultActiveKey={language.default.code}>
            {language.list.map((lang) => (
              <Tabs.TabPane tab={lang.code} key={lang.code} forceRender>
                <Form.Item
                  label='Value'
                  name={['value', lang.code]}
                  rules={[{
                    required: lang.code === language.default.code || (lang.code === language.default.code && attribute['type'] === 'COLOR' && form.getFieldValue(['value', 'sr']) === undefined),
                    message: 'Please enter value!'
                  }]}
                >
                  {attribute['type'] === 'COLOR' ? (
                    <input type="color" defaultValue={initialValues?.value[lang.code] || "#fff"} onChange={(e) => { form.setFieldValue('value', { 'sr': e.target.value, 'en': e.target.value }); }} />
                  ) : (
                    <Input defaultValue={initialValues?.value[lang.code] || ''} />
                  )}
                </Form.Item>
              </Tabs.TabPane>
            ))}
          </Tabs>
          <Divider type='horizontal' />
          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </div>
        </Form>

      </div>
    </div>
  );
};

export default ValueForm;

import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import OrderForm from '../../components/forms/OrderForm';

const EditData = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [order, fetchOrder] = useAxios('', {}, currentuser.data.token, 'get');
  const { orderId } = props.match.params;

  useEffect(() => {
    if (orderId) fetchOrder(`${SERVER_URL}/orders/${orderId}`, {});
  }, [fetchOrder, orderId]);

  const onSubmit = async (formData) => {
    const method = 'put';
    const route = `${SERVER_URL}/orders/${orderId}`;

    try {
      await Axios[method](
        route,
        { status: formData.status },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      notification.success({
        message: `Order updated.`,
        placement: 'bottomRight',
      });
      history.push('/admin/orders');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const createInvoice = async () => {
    const method = 'post';
    const route = `${SERVER_URL}/invoices`;
    try {
      await Axios[method](
        route,
        { orderId: orderId, dateOfPayment: order.data.createdAt },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: `Invoice created.`,
        placement: 'bottomRight',
      });
      history.push('/admin/orders');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !order.isLoading && Object.keys(order.data).length > 0 && currentuser.language;
  // if (isDataFetched) {
  //   order.data.user.userName = order.data.user.firstName + ' ' + order.data.user.lastName;
  // }
  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/orders'>
          <Button type='primary'>All orders</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}
        {orderId && !order.isError && order.data && isDataFetched && (
          <OrderForm
            data={order.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            token={currentuser.data.token}
            createInvoice={createInvoice}
          />
        )}
      </div>
    </div>
  );
};

export default EditData;
